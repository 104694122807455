// Simulating bootstrap's container class
// breakpoints: 1450px 1200px 750px
.width-container {
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;
}

.width-container-home-cards {
    max-width: 1550px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 1580px) {
    .width-container-home-cards {
        max-width: 1200px;
    }
}


@media screen and (max-width: 1450px) {
    .width-container {
        max-width: 1100px;
    }
    .width-container-home-cards {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1200px) {
    .width-container {
        max-width: none;
        padding: 0 3%;
    }
    .width-container-home-cards {
        max-width: 780px;
    }
}
