
@font-face {
    font-family: "Roboto";
    src: url("assets/fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: "SFLight";
    src: url("assets/fonts/SanFranciscoDisplay-Thin.otf");
}

@font-face {
    font-family: "RobotoReg";
    src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Bell MT";
    src: url("assets/fonts/BELL.TTF");
}

@font-face {
    font-family: "Myriad Pro Bold";
    src: url("assets/fonts/MyriadPro-Bold.otf");
}

@font-face {
    font-family: "Myriad Pro";
    src: url("assets/fonts/MyriadPro-Regular.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("assets/fonts/helvetica-normal.ttf");
}

@font-face {
    font-family: "Mohave";
    src: url("assets/fonts/Mohave-Regular.otf");
}

@font-face {
    font-family: "Menlo";
    src: url("assets/fonts/Menlo-Regular.ttf");
}
