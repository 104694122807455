@import "fonts";
@import "common-css/width-container";
@import "common-css/admin-panel-styles";
@import "~highlight.js/styles/base16/atelier-plateau-light.css";
@import "common-css/material-theme";

/*For selection color*/
::-moz-selection { /* Code for Firefox */
    color: white;
    background: orange;
}

::selection {
    color: white;
    background: orange;
}

html, body {
    margin: 0 0 0 0;
    padding: 0;
    height: 100%;
}

.flex {
    display: flex;
}

.clear-text-paddings {
    padding: 0;
    margin: 0;
}

.round-corner {
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
}

.center_div {
    margin: auto;
}

// also need to specify a width
.center-div-horizontally {
    margin: 0 auto;
}

.center-text-absolute {
    width: 100%;
    text-align: center;
}
/////////////////////
////// FONTS ///////
////////////////////
.helvetica {
    font-family: Helvetica, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif";
}

.helvetica-normal {
    font-family: Helvetica, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif";
    font-weight: normal;
}

.mohave {
    font-family: Mohave, sans-serif;
}

.menlo {
    font-family: Menlo, "Courier New", sans-serif;
}

/////////////////////
//// END FONTS //////
/////////////////////

.center_img  { /*THE AMAZING CODE THAT CENTERS THE GODDAMN IMAGE*/ }

.center_img img  {
    display: block;
    margin: 0 auto;
}

.zoom-fx {
    &:hover {
        transition: all 0.3s ease-in-out;
        transform:scale(1.1,1.1);
    }
}

.orange-underline-anchor {
    text-decoration: underline;
    transition: all .1s ease-in;
    &:visited, &:link {
        color: black;
    }
    &:hover {
        color: orange;
    }
}

.orange-underline-anchor-light {
    text-decoration: underline;
    transition: all .1s ease-in;
    &:visited, &:link {
        color: white;
    }
    &:hover {
        color: orange;
    }
}

.underline {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: dimgray;
}

.overline {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: dimgray;
}

.horizontal-scroll-outer {
    overflow: auto;
    white-space: nowrap;
}

.horizontal-scroll-inner {
    display: inline-block;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.minimal-top-spacing {
    margin-top: 0;
    padding-top: 0;
}

.theme-button {
    background-color: dimgrey;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.5);
    border-radius: 10px;
    letter-spacing: 2px;
    font-family: Mohave, sans-serif;
}

.top-right-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.corner-display-container {
    position: relative;
}

.x-icon {
    width: 30px;
    height: 1px;
    padding: 14.5px 0;
    background-color: black;
    border-radius: 50%;
    cursor: pointer;
    p {
        padding: 0;
        color: rgb(242, 242, 242);
        font-weight: bold;
        text-align: center;
        //line-height: 25px;
        margin-top: -10px;
    }
}

.italic {
    font-style: italic;
}
/// Predefined font sizes
.large-text { font-size: 80px; }
.medium-large-text { font-size: 40px; }
.medium-text { font-size: 30px; }
.small-text { font-size: 25px; }
.extra-small-text { font-size: 18px; }

@media screen and (max-width: 1200px) {
    .large-text { font-size: 60px; }
    .medium-large-text { font-size: 35px; }
    .medium-text { font-size: 25px; }
}

@media screen and (max-width: 800px) {
    .large-text { font-size: 40px; }
    .medium-large-text { font-size: 30px; }
    .medium-text { font-size: 20px; }
    .small-text { font-size: 20px; }
    .extra-small-text {font-size: 16px;}
}
