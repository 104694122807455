$admin-bg-gray: rgb(240, 240, 240);

input[type=text] {
    width: 100%;
    padding: 8px 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
}

.admin-button {
    height: 33px;
    background-color: white;
    color: black;
    border: 1px solid black; /* Green */
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    &:hover {
        cursor: pointer;
    }
}

.p-non-button {
    margin: 0;
    padding: 5px 0 0 0;
}

.p-button {
    margin: 0;
    padding: 7px 0 0 0;
}

label, textarea, input {
    display: inline-block;
}

.vertical-spacing {
    margin: 10px 0 5px 0;
}

.vertical-spacing-button {
    margin: 10px 0;
}

.left-half {
    width: 380px;
    margin-right: 50px;
}

.right-half {
    width: 400px;
}

.activity-indicator {
    width: 60px;
    height: 30px;
    margin-top: -25px;
    margin-left: auto;
}

.admin-header {
    margin: 0 5px 0 0;
    padding: 20px 0 20px 0;
    font-size: 26px;
}

.admin-section {
    padding: 20px 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.admin-textarea {
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 10px;
    font-size: 12px;
    box-sizing: border-box;
}

.admin-single-project {
    background-color: $admin-bg-gray;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 10px;

    h5, h4 {
        margin: 5px 0;
        padding: 0;
    }

    img {
        margin-right: 10px
    }
}

.admin-aspect-ratio-img {
    max-width: 70px;
    max-height: 70px;
}

.admin-select {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    font-size: 15px;
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .5em 1.4em .5em .7em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 35px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("~src/assets/images/admin/down-arrow.png") no-repeat right #eeeeee;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &::-ms-expand {
        display: none;
    }

    &:hover {
        border-color: #888;
    }

    &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
    }

    option {
        font-weight:normal;
    }
}
